<style scoped lang="less">
.Teacher-Notice {
  background: #fff;
  padding: 20px;
  box-shadow: @shadow;

  .stu-module-header {
    margin-bottom: 20px;
  }
  .drawer-div {
    position: relative;
    height: 100%;
    padding: 0 20px 20px;
    .drawer-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .drawer-time {
      font-size: 12px;
      color: #999;
      margin-bottom: 20px;
    }

    .drawer-content {
      min-height: 300px;
      overflow-y: auto;
      font-size: 14px;
    }
  }
}
</style>
<template>
  <div class="Teacher-Notice">
    <div class="stu-module-header">
      <div class="stu-module-title">教务公告</div>
    </div>
    <div v-if="queryRoot">
      <el-form :model="form" inline size="medium">
        <el-form-item label="标题">
          <el-input
            v-model="form.title"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="选择" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="已读" :value="1"></el-option>
            <el-option label="未读" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起止时间">
          <el-date-picker
            v-model="date"
            format="yyyy/MM/dd"
            value-format="yyyy/MM/dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="query" type="primary">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table :data="tableData" :height="450" v-loading="loading">
        <el-table-column label="序号" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (pageNum - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <el-table-column
          label="标题"
          prop="title"
          min-width="220"
          align="center"
        >
          <template slot-scope="scope">
            <el-link
              :underline="false"
              type="primary"
              @click="handleNoticeItem(scope.row)"
              >{{ scope.row.title }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          label="时间"
          prop="createTime"
          min-width="140"
          align="center"
        ></el-table-column>
        <el-table-column label="状态" prop="status" align="center" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0" style="color: #ff8c00"
              >未读</span
            >
            <span v-if="scope.row.status == 1" style="color: #ff8c00"
              >已读</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        background
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <no-root v-else></no-root>
    <el-drawer :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="drawer-div" v-loading="drawerLoading">
        <div class="drawer-title" v-html="noticeData.title"></div>
        <div class="drawer-time" v-html="noticeData.createTime"></div>
        <div
          class="drawer-content"
          v-html="noticeData.content || '暂无信息'"
        ></div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  SelectEduCourseNoticeByPage,
  SelectEduNoticeById,
} from "@/libs/api/teacher/user";
export default {
  name: "teacher-notice",
  data() {
    return {
      loading: false,
      noticeData: {
        title: "",
        content: "",
      },
      drawerLoading: false,
      dialogVisible: false,
      date: [],
      form: {
        title: "",
        status: "",
      },

      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
    };
  },
  computed: {
    queryRoot() {
      return this.$sysPerKey("eduTeacherNotice:list");
    },
  },
  watch: {
    queryRoot(n) {
      n && this.query();
    },
  },
  mounted() {
    this.queryRoot && this.query();
  },
  methods: {
    handleClose(done){
      this.query();
      done();
    },
    handleNoticeItem(item) {
      if (!this.$sysPerKey("eduTeacher:noticeInfo")) {
        return;
      }
      this.dialogVisible = true;
      this.drawerLoading = true;
      SelectEduNoticeById({
        noticeId: item.noticeId,
      })
        .then((res) => {
          this.drawerLoading = false;
          if (res.data) this.noticeData = res.data;
        })
        .catch(() => {
          this.drawerLoading = false;
        });
    },
    query() {
      let obj = this.form;
      if (this.date && this.date.length) {
        obj = {
          ...this.form,
          startTime: this.date[0],
          endTime: this.date[1],
        };
      }
      this.loading = true;
      SelectEduCourseNoticeByPage({
        page: this.pageNum,
        pageSize: this.pageSize,
        ...obj,
      })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.$store.dispatch("setHeaderNotice", this.tableData[0].title);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    init() {},
    handleCurrentChange(val) {
      this.pageNum = val;
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
  },
};
</script>
